import { TabsInterface, TabsLabelsInterface } from './interfaces';
import moment from 'moment';
import { formatPrice } from './services';

export const CRYPTO_JS_SECRET = 'sdSH67ksdGHDbdfshwe23gbsY7820vHGds';

export const JWT_KEY = 'jwt';

export const TABS_LABELS: TabsLabelsInterface = {
  NEW: 'NEW',
  NEEDS_FULFILLMENT: 'NEEDS FULFILLMENT',
  NEEDS_DELIVERY: 'NEEDS DELIVERY',
  MERCH_DELIVERY: 'MERCH DELIVERY',
  PENDING_REC: 'PENDING REC',
  COMPLETED: 'COMPLETED',
  REJECTED: 'REJECTED',
  VIEW_ALL: 'ALL',
  VOID: 'VOID',
  COMMISSION_REPORT: 'COMMISSION REPORT',
  CATEGORY_REPORT: 'CATEGORY REPORT',
  INVENTORY_DEPT_SALES_REPORT: 'INVENTORY DEPT SALES REPORT',
  AFFILATE_REPORT: 'AFFILATE REPORT',
  UNSENT_ORDER_REPORT: 'UNSENT ORDER REPORT',
  DAILY_PRICING_REPORT: 'DAILY PRICING REPORT',
  DAILY_INVENTORY_DEPT_REPORT: 'DAILY INVENTORY DEPT REPORT',
  MANAGE_USERS: 'MANAGE USERS',
  MANAGE_REPORT_RECIPIENTS: 'MANAGE REPORT RECIPIENTS',
  GENERATE_REPORT: 'GENERATE REPORT',
  MANAGE_USER_REPORTS: 'MANAGE USER REPORTS',
  CLIENTS: 'CLIENTS',
  RECIPIENTS: 'RECIPIENTS',
  USERS: 'USERS AND ROLES',
};

export const TABS: TabsInterface = {
  'VIP-WORKFLOW': [
    TABS_LABELS.NEW,
    TABS_LABELS.NEEDS_FULFILLMENT,
    TABS_LABELS.NEEDS_DELIVERY,
    TABS_LABELS.MERCH_DELIVERY,
    TABS_LABELS.PENDING_REC,
    TABS_LABELS.COMPLETED,
    TABS_LABELS.REJECTED,
    TABS_LABELS.VIEW_ALL,
    TABS_LABELS.VOID,
  ],
  CLIENTS: [TABS_LABELS.CLIENTS, TABS_LABELS.RECIPIENTS, TABS_LABELS.USERS],
  REPORTS: [TABS_LABELS.GENERATE_REPORT, TABS_LABELS.MANAGE_USER_REPORTS],
  DOCS: [
    TABS_LABELS.UNSENT_ORDER_REPORT,
    TABS_LABELS.DAILY_PRICING_REPORT,
    TABS_LABELS.DAILY_INVENTORY_DEPT_REPORT,
  ],
  ADMIN: [TABS_LABELS.MANAGE_USERS, TABS_LABELS.MANAGE_REPORT_RECIPIENTS],
};

export const DOTS = 'DOTS';

export const IS_EXPANDABLE_ROWS = [
  TABS_LABELS.NEEDS_FULFILLMENT,
  TABS_LABELS.NEEDS_DELIVERY,
  TABS_LABELS.PENDING_REC,
  TABS_LABELS.COMPLETED,
  TABS_LABELS.VIEW_ALL,
  TABS_LABELS.MERCH_DELIVERY,
];

export const editableCartTypes = [
  'Airport Transfer',
  'Airport Transfer Arrival',
  'Airport Transfer Departure',
  'Hotel',
  'Limo',
  'Dinner',
  'Ticket',
  'Gift Bundle',
  'CA Gift Card',
  'City Pass',
  'Rideshare Pass',
  'Fanatics',
  'Fanatics Credit',
  'GiftCard',
  'Memorabilia',
  'MLB Card',
  'NBA Card',
  'NHL Card',
  'Multi-day Gift Card',
  'Parking',
  'Tailgate',
  'Transfer',
  'Vegas Limo Transfer',
  'Vegas Limo Transfer Arrival',
  'Vegas Limo Transfer Departure',
  'Outsourced',
];

export const FULLY_EDITABLE_CARDS = [
  'MeetGreet',
  'Broadway Virtual Lesson',
  'Courtesy Credit',
  'Additional Gifts',
  'Gift',
  'Pit pass',
  'Other',
  'VIP Club Access',
  'Virtual Lesson',
  'VR Headset',
  'New Card',
  'Virtual M&amp;G',
  'Virtual Message',
  'Replacement Gifts',
  'Merchandise Credit',
];

export const tableFieldTypes = {
  price: 'price',
  bool: 'bool',
  percent: 'percent',
  string: 'string',
  number: 'number',
};

export const TABLE_INPUT_TYPES = {
  date: 'date',
  text: 'text',
  number: 'number',
  datetime: 'datetime-local',
  phoneNumber: 'tel',
  textarea: 'textarea',
  select: 'select',
  time: 'time',
  checkbox: 'checkbox',
  file: 'file',
};

export const DELIVERED_OPTIONS = {
  IP: 'In Package',
  OD: 'Ordered/Delivered',
  ND: 'Needs Delivery',
};

export const STATUS_OPTIONS = {
  1: 'Pending Authorization',
  2: 'Authorized',
  4: 'Rejected',
  5: 'Ready To Ship',
  6: 'Rejected',
  7: 'Void',
};

export const CART_FILTERS = {
  all: 'All',
  airportTransfer: 'Airport Transfers',
  hotel: 'Hotel',
  transportation: 'Transportation',
  hospitality: 'Hospitality',
  event: 'Event',
  merchandise: 'Merchandise',
  additionalMerch: 'Additional Merch',
  eventCredits: 'Event Credits',
  cityPass: 'CityPASS',
  credit: 'Credit',
  merchandiseCredit: 'Merchandise Credit',
  memorabilia: 'Memorabilia',
  meetGreet: 'Meet & Greet',
  other: 'Other',
  pitPass: 'Pit Pass',
  parking: 'Parking',
  tailgate: 'Tailgate',
  giftBundle: 'Gift Bundle',
  rideSharePass: 'Rideshare Pass',
  replacementGifts: 'Replacement Gifts',
};

export const CARTS_BY_FILTERS = {
  airportTransfer: ['Airport Transfers', 'Airport Transfer Arrival', 'Airport Transfer Departure'],
  hotel: ['Hotel Details'],
  transportation: ['Transportation Services', 'Vegas Limo Transfer', 'Vegas Limo Transfer Arrival', 'Vegas Limo Transfer Departure'],
  hospitality: ['Pre-show Hospitality', 'VIP Club Access'],
  event: ['Event Details'],
  merchandise: ['Merchandise'],
  additionalMerch: ['Additional Merchandise'],
  virtualLesson: ['Virtual Lesson'],
  eventCredits: ['Food / Beverage / Merchandise Credits'],
  cityPass: ['CityPASS Details'],
  credit: ['Credit'],
  merchandiseCredit: ['Merchandise Credit'],
  meetGreet: ['Meet & Greet', "MeetGreet", "Meet and Greet", "Meet&Greet"],
  memorabilia: ['Memorabilia'],
  pitPass: ['Pit pass'],
  other: ['Other'],
  parking: ['Parking'],
  tailgate: ['Tailgate'],
  VRHeadset: ['VR Headset'],
  giftBundle: ['Gift Bundle'],
  rideSharePass: ['Rideshare Pass'],
  replacementGifts: ['Replacement Gifts'],
};

export const CLIENT_ROLES = ['BROKER', 'RECIPIENT'];
export const ADMIN_ROLES = ['ADMIN', 'SUPER_ADMIN'];

export const responseKeys = {
  [TABS_LABELS.NEW]: 'newPackageOrders',
  [TABS_LABELS.NEEDS_FULFILLMENT]: 'needsFulfillmentOrders',
  [TABS_LABELS.NEEDS_DELIVERY]: 'needsDelivery',
  [TABS_LABELS.MERCH_DELIVERY]: 'merchDelivery',
  [TABS_LABELS.PENDING_REC]: 'pendingRec',
  [TABS_LABELS.COMPLETED]: 'completedOrders',
  [TABS_LABELS.REJECTED]: 'rejectedOrders',
  [TABS_LABELS.VOID]: 'voidOrders',
  [TABS_LABELS.VIEW_ALL]: 'allOrders',
};

export const SERVICE_STATE = {
  DECLINED: 'DECLINED',
  UNDECLINED: 'UNDECLINED',
};

export const STAGE_ONE_CARDS = [
  "Airport Transfer",
  "Airport Transfer Arrival",
  "Airport Transfer Departure",
  "Limo",
  "City Pass",
  "Dinner",
  "Vegas Limo Transfer",
  "Vegas Limo Transfer Arrival",
  "Vegas Limo Transfer Departure",
  "Hotel",
];

export const VIP_CLUB_TYPES =  {
  17: 'Electronic',
  18: 'AXS Mobile',
  19: 'MLB Ballpark App Mobile',
  20: 'SeatGeek Mobile',
  21: 'PDF Printout',
  22: 'Mobile QR',
  23: 'Mobile Transfer Link',
  24: 'Hardstock',
  25: 'Will Call',
  38: 'Ticketmaster Mobile',
  26: 'Other',
};

export const PIT_PASS_TYPES =  {
  27: 'Electronic',
  28: 'AXS Mobile',
  29: 'MLB Ballpark App Mobile',
  30: 'SeatGeek Mobile',
  31: 'PDF Printout',
  32: 'Mobile QR',
  33: 'Mobile Transfer Link',
  34: 'Hardstock',
  35: 'Will Call',
  39: 'Ticketmaster Mobile',
  36: 'Other'
};

export const transformTicketMessageBeforeSent = (
  ticketTypes: any,
  ticketType: number,
  packageOrder: any,
  mobileTransferLink: unknown,) => {
  const ticketTypeObj = ticketTypes.find((el: { id: number; }) => Number(el.id) === Number(ticketType));
  if (ticketTypeObj) {
    return ticketTypeObj?.descriptionBeforeSent
    .replaceAll(/\${eventName}/g, packageOrder.eventName)
    .replaceAll(/\${mobileTransferLink}/g, mobileTransferLink ? '<a href=' + mobileTransferLink + ' target="_blank" >' + mobileTransferLink + '</a>' : '')
    .replaceAll(/\${trackingNumber}/g, '  XXXXXXXXX')
    .replaceAll(/\${sentDateMessage}/g, '-');
  } else {
    return '';
  }
};

export const transformTicketMessageAfterSent = (
  ticketTypes: any,
  ticketType: number,
  packageOrder: any,
  mobileTransferLink: unknown,
  ticketsSentDateValue: any) => {
  const ticketsSentDate =
    ticketsSentDateValue && ticketsSentDateValue !== 'Invalid date'
      ? moment(new Date(ticketsSentDateValue)).format('MM/DD/YYYY')
      : null;    
  const sentDateMessage = ticketsSentDate ? ` on ${ticketsSentDate}` : '';
  const ticketTypeObj = ticketTypes?.find((el: any) => Number(el.id) === Number(ticketType));
  if (ticketTypeObj) {
    return ticketTypeObj?.descriptionAfterSent
      .replaceAll(/\${eventName}/g, packageOrder.eventName)
      .replaceAll(/\${sentDateMessage}/g, sentDateMessage)
      .replaceAll(/\${mobileTransferLink}/g, mobileTransferLink ? '<a href=' + mobileTransferLink + ' target="_blank" >' + mobileTransferLink + '</a>' : '')
      .replaceAll(/\${trackingNumber}/g, '  XXXXXXXXX');
      // .replaceAll(/\${trackingNumber}/g, packageOrder?.trackingNumber || '  XXXXXXXXX');
  } else {
    return '';
  }
};

export const NEW_CARD_ID = 334;

export const PARKING_OPTION_DESCRIPTION = {
  Mobile: 'Your parking pass will be transferred to you.',
  'Not Mobile':
    'Is attached to this card.  Please present at the parking location noted on the pass.',
  ParkWhiz:
    'Parking will be emailed to you from ParkWhiz. Check your spam folder if you do not see it by 24 hours before the event. Please note: Some parking locations will ask for vehicle information, including license plate number, to be added to the parking pass. You will be prompted to do so when viewing your parking pass. If you are not prompted to add any information you are good to go!',
  'License plate required':
    'Your vehicle make and license plate # is connected to your parking pass. ',
};

export const transformTailgateOptionDescription = (
  ticketTypeId: number,
  ticketTypes: any,
) => {
  if (ticketTypeId) {
    return ticketTypes?.find((el: { id: number; }) => Number(el.id) === Number(ticketTypeId))?.description;
  }
};

export const TRANSFER_STATUSES = ['TRANSFERABLE', 'EMAILED', 'FAILED', 'PENDING'];

export const  transformCardDesciptions = (groupId: number, merchDesc: any, cardDescriptions: any[]) => {
  if (groupId) {
    const cardDescription = cardDescriptions?.find((el: { groupId: number; }) => Number(el.groupId) === Number(groupId));
    if (cardDescription) {
      return cardDescription?.descriptionPublished
        .replaceAll(
          /\${merchDesc}/g,
          merchDesc
        );
    }
  };
};

export const  transformDinnerCardDesciption = (groupId: number, cardDescriptions: any[], cartData: Record<string, any>) => {
  const eventDateValue = cartData.date ? moment(cartData.date).format('h:mm a') : '-';
  if (groupId) {
    const cardDescription = cardDescriptions?.find((el: { groupId: number; }) => Number(el.groupId) === Number(groupId));
    if (cardDescription) {
      return cardDescription?.descriptionPublished
          .replaceAll(
            /\${eventDate}/g,
            eventDateValue
          ).replaceAll(
            /\${qty}/g,
            (formatPrice(cartData?.foodBeverageCredit)).toString()
          );
    }
  }
};

export const transformTicketMessageByType = (
  ticketTypeId: number,
  ticketTypes: any,
) => {
  if (ticketTypeId) {
   const description = ticketTypes?.find((el: { id: number; }) => Number(el.id) === Number(ticketTypeId))?.description;
   return description ? description.replaceAll(/\${trackingNumber}/g, '  XXXXXXXXX') : '';
  }
};