import { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Formik } from 'formik';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Modal } from 'reactstrap';
import * as Yup from 'yup';
import { successMsg } from '../../cache/vars';
import { CREATE_NEW_CARD } from '../../queries/vip-workflow';
import CloseModalButton from '../Buttons/CloseModalButton';
import { NEW_CARD_ID } from '../../utils/constants';

const VALIDATION_SCHEMA = Yup.object().shape({
  description: Yup.string().optional(),
});

const CreateNewCardModal = ({
  isModalOpened,
  setIsModalOpened,
  currentInvoiceId,
  setPackageOrderWithDetails,
}: any) => {
  const client = useApolloClient();

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const FORM_STATE = {
    invoiceId: currentInvoiceId,
    type: 'Components',
    title: '',
    description: '',
    budget: 0,
    csr: '0',
    unfulfilled: 1,
    typeId: NEW_CARD_ID,
    groupId: 4,
  };

  return (
    <Modal
      isOpen={isModalOpened}
      toggle={() => {
        setIsModalOpened(false);
      }}
      className="modal-dialog-centered modal-secondary"
      size="xl"
      fade={false}
    >
      <div className="modal-body pb-0">
        <div className="d-flex justify-content-between ">
          <div>
            <h1>Create Card</h1>
          </div>
          <div>
            <CloseModalButton
              handleCloseModalButtonClick={(e) => {
                e.preventDefault();
                setIsModalOpened(false);
              }}
            />
          </div>
        </div>
        <Formik
          initialValues={{ ...FORM_STATE }}

          onSubmit={async (cardData) => {
            if (isSubmitDisabled) return;
          
            setIsSubmitDisabled(true);
            try {
              const { data } = await client.mutate({
                mutation: CREATE_NEW_CARD,
                variables: { cardData },
              });
          
              if (data) {
                setPackageOrderWithDetails((prev: any) => ({
                  ...prev,
                  orderDetails: [data.createPackageDetails, ...prev.orderDetails],
                }));
                successMsg('Saved');
                setIsModalOpened(false);
              }
            } catch (error) {
              console.error('Error creating card:', error);
            } finally {
              setTimeout(() => setIsSubmitDisabled(false), 3000);
            }
          }}
          validationSchema={VALIDATION_SCHEMA}
        >
          {(props) => {
            const {
              values,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched,
            } = props;

            return (
              <Form autoComplete="off" onSubmit={handleSubmit} className="d-flex flex-wrap">
                {/* <Col xs="6">
                  <FormGroup>
                    <label className="form-control-label">Title</label>
                    <Input
                      type="text"
                      name="title"
                      placeholder="Title"
                      onChange={handleChange}
                      value={values.title}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                </Col> */}
                <Col xs="6">
                  <FormGroup>
                    <label className="form-control-label">Description</label>
                    <Input
                      type="text"
                      name="description"
                      placeholder="Description (optional)"
                      onChange={handleChange}
                      value={values.description}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                </Col>
                {/* <Col xs="6">
                  <FormGroup>
                    <label className="form-control-label">Budget</label>
                    <Input
                      invalid={!!errors.budget && touched.budget}
                      type="number"
                      onChange={handleChange}
                      value={values.budget}
                      name="budget"
                      placeholder="Budget"
                      onBlur={handleBlur}
                      min={0}
                    />
                    {errors.budget && touched.budget ? (
                      <FormFeedback>Field should not be empty</FormFeedback>
                    ) : null}
                  </FormGroup>
                </Col> */}
                <Col xs="12">
                  <div className="text-right">
                    <Button
                      className="my-4"
                      color="info"
                      type="submit"
                      disabled={(!dirty && isSubmitting) || isSubmitDisabled}
                    >
                      Create Card
                    </Button>
                  </div>
                </Col>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default CreateNewCardModal;
