import moment from 'moment';
import { useApolloClient, useQuery } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { Modal, ModalBody, ModalHeader, PopoverBody, UncontrolledPopover } from 'reactstrap';

import { safeParseNestedJson } from '../../utils/services';
import { GET_CART_DETAILS_LOGS } from '../../queries/vip-workflow';

import './fieldChangesPopover.scss';

const formatDate = (dateStr: string): string => {
  if (!dateStr) return '-';
  if (dateStr.includes('T') || dateStr.includes(' ')) {
    if (dateStr.match(/(\d{2}):(\d{2}):(\d{2})/) || dateStr.match(/(\d{2}):(\d{2})/)) {
      return moment(dateStr).format('MM/DD/YYYY HH:mm:ss');
    }
  }
  return moment(dateStr).format('MM/DD/YYYY');
};

const formatValueIfDate = (val: string): string => {
  if (!val) return val;
  
  if (val.trim().startsWith('{') || val.trim().startsWith('[')) {
    try {
      const parsed = JSON.parse(val);
      if (typeof parsed === 'string' && moment(parsed, moment.ISO_8601, true).isValid()) {
        return formatDate(parsed);
      }
    } catch (e) {
      console.error('Error parsing value:', e);
    }
  }
  
  return moment(val, moment.ISO_8601, true).isValid() ? formatDate(val) : val;
};

interface FieldChangesButtonProps {
  updated: boolean;
  updatedBy: string;
  newValue: string;
  oldValue: string;
  updatedAt: string;
  packageDetailsId: number;
  fieldName: string;
  transferType: string;
  config?: Record<string, any>;
}

const FieldChangesButton: React.FC<FieldChangesButtonProps> = ({
  updated = false,
  updatedBy = 'unknown',
  newValue = '',
  oldValue = '',
  updatedAt = '',
  packageDetailsId = null,
  fieldName = '',
  transferType = '',
  config = null,
}) => {
  const client = useApolloClient();

  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [fullLogEntries, setFullLogEntries] = useState<any[]>([]);

  const buttonRef = useRef(null);

  const extractLogsForField = (fullLogsArray: any[], fieldName: string, transferType: string) => {
    return fullLogsArray?.reverse()?.reduce((acc, logEntry) => {
      const parsedNewValue = safeParseNestedJson(logEntry?.new_value);
      const parsedOldValue = safeParseNestedJson(logEntry?.old_value);
    
      const formatArrayValues = (arr: any[]) =>
        Array.isArray(arr)
          ? arr
              .map((item) =>
                Object.entries(item)
                  .map(([key, value]) => `${key}: ${value}`)
                  .join('\n')
              )
              .join('\n')
          : '-';
    
      if (logEntry && (parsedNewValue || parsedOldValue) && (Array.isArray(parsedNewValue) || Array.isArray(parsedOldValue))) {
        acc.push({
          updated_by: logEntry?.updated_by || 'unknown',
          new_value: formatArrayValues(parsedNewValue),
          old_value: formatArrayValues(parsedOldValue),
          updated_at: logEntry?.updated_at ? formatDate(logEntry.updated_at) : '-',
        });
      } else if ((logEntry?.transfer1 || logEntry?.transfer2) && transferType && logEntry?.[transferType]?.[fieldName]) {
        acc.push({
          updated_by: logEntry?.[transferType]?.[fieldName]?.updated_by || 'unknown',
          new_value: formatValueIfDate(logEntry?.[transferType]?.[fieldName]?.new_value || '-'),
          old_value: formatValueIfDate(logEntry?.[transferType]?.[fieldName]?.old_value || '-'),
          updated_at: logEntry?.[transferType]?.[fieldName]?.updated_at ? formatDate(logEntry[transferType][fieldName].updated_at) : '-',
        });
      } else if (logEntry && fieldName === 'vendorPhone') {
        acc.push({
          updated_by: logEntry?.[fieldName]?.updated_by || 'unknown',
          new_value: formatValueIfDate(logEntry?.[fieldName]?.new_value || '-'),
          old_value: formatValueIfDate(logEntry?.[fieldName]?.old_value || '-'),
          updated_at: logEntry?.[fieldName]?.updated_at ? formatDate(logEntry[fieldName].updated_at) : '-',
        });
      } else if (logEntry && fieldName === 'attendeeName') {
        const newValueArr = safeParseNestedJson(logEntry?.['attendeeNames']?.new_value);
        const oldValueArr = safeParseNestedJson(logEntry?.['attendeeNames']?.old_value);
        acc.push({
          updated_by: logEntry?.['attendeeNames']?.updated_by || 'unknown',
          new_value: Array.isArray(newValueArr) ? newValueArr.map((item) => formatValueIfDate(item.attendeeName)).join('\n') : '-',
          old_value: Array.isArray(oldValueArr) ? oldValueArr.map((item) => formatValueIfDate(item.attendeeName)).join('\n') : '-',
          updated_at: logEntry?.['attendeeNames']?.updated_at ? formatDate(logEntry['attendeeNames'].updated_at) : '-',
        });
      } else if (logEntry && fieldName === 'placeName' && config?.showDataFrom === 'venueAddress') {
        acc.push({
          updated_by: logEntry ? logEntry?.['venueAddress']?.updated_by : 'unknown',
          new_value: logEntry ? logEntry?.['venueAddress']?.new_value : '-',
          old_value: logEntry ? logEntry?.['venueAddress']?.old_value : '-',
          updated_at: logEntry?.['venueAddress']?.updated_at ? formatDate(logEntry['venueAddress'].updated_at) : '-',
        });
      } else if (logEntry && logEntry?.[fieldName]) {
        acc.push({
          updated_by: logEntry?.[fieldName]?.updated_by || 'unknown',
          new_value: formatValueIfDate(logEntry?.[fieldName]?.new_value || '-'),
          old_value: formatValueIfDate(logEntry?.[fieldName]?.old_value || '-'),
          updated_at: logEntry?.[fieldName]?.updated_at ? formatDate(logEntry[fieldName].updated_at) : '-',
        });
      }
    
      return acc;
    }, []);    
  };

  const onHandleShowFullLogs = async () => {
    if (!packageDetailsId) return;

    const { data } = await client.query({
      query : GET_CART_DETAILS_LOGS,
      variables: {
        packageDetailsId: packageDetailsId
      },
      fetchPolicy: 'no-cache'
    });
    
    if (!data?.getCartDetailsLogs?.cartJsonDataFullLogs) return;

    try {
      const fullLogsArray = JSON.parse(data.getCartDetailsLogs.cartJsonDataFullLogs);
      const selectedLogs = extractLogsForField(fullLogsArray, fieldName, transferType);

      if (selectedLogs.length) {
        setFullLogEntries([...selectedLogs]);
        setModalOpen(true);
      } else {
        setFullLogEntries([]);
        setModalOpen(true);
      }
    } catch (error) {
      console.error('Error parsing full logs:', error);
      setFullLogEntries([]);
      setModalOpen(true);
    }
  };
  
  const showPopover = () => {
    setPopoverOpen(true);
  };

  const hidePopover = () => {
    setPopoverOpen(false);
  };

  return (
    <>
      <button
        type="button"
        style={{ border: '0', background: 'none', padding: '0', cursor: 'pointer' }}
        ref={buttonRef}
        onMouseEnter={showPopover}
        onMouseLeave={hidePopover}
        onClick={onHandleShowFullLogs}
      >
        <i className="fa fa-solid fa-circle-info ml-2" style={{ color: updated ? '#ffcc00' : '#2dce89'}}></i>
      </button>

      <UncontrolledPopover
        isOpen={popoverOpen}
        style={{ maxWidth: '30rem' }}
        placement="right"
        target={buttonRef}
      >
        <PopoverBody>
          {updated && (
            <div>
              <p>
                <strong>User:</strong> {updatedBy}
              </p>
              <p>
                <strong>New Value:</strong> <pre className="pre-popover-value">{newValue}</pre>
              </p>
              <p>
                <strong>Old Value:</strong> <pre className="pre-popover-value">{oldValue}</pre>
              </p>
              <p>
                <strong>Updated At:</strong> {updatedAt && formatDate(updatedAt)}
              </p>
            </div>
          )}
          {!updated && <span>No changes available</span>}
        </PopoverBody>
      </UncontrolledPopover>

      <Modal style={{ maxHeight: "90%", overflowY: "auto"}} isOpen={modalOpen} toggle={() => setModalOpen(false)}>
        <ModalHeader className="align-items-center" style={{ borderBottom: '1px solid #00000010'}} toggle={() => setModalOpen(false)}>
          Full Log for {fieldName}
        </ModalHeader>
        <ModalBody>
          {fullLogEntries.length ? (
            <div>
              {fullLogEntries.map((entry, index) => (
                <div key={`logfield-${index}`} className="log-entry">
                  <p className="pre-modal-value" style={{ margin: 0 }}><strong>New Value:</strong> {entry.new_value}</p>
                  <p className="pre-modal-value" style={{ margin: 0 }}><strong>Old Value:</strong> {entry.old_value}</p>
                  <p style={{ margin: 0 }}><strong>Updated By:</strong> {entry.updated_by}</p>
                  <p style={{ margin: 0 }}><strong>Updated At:</strong> {entry.updated_at}</p>
                  {index < fullLogEntries.length - 1 && <hr style={{ marginTop: "10px", marginBottom: "10px" }}/>} 
                </div>
              ))}
            </div>
          ) : (
            <p>No log entries available for this field.</p>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export const renderFieldChanges = ({ config, logs, index = 0, packageDetailsId = '' }: any) => {
  const data = logs ? JSON.parse(logs) : null;
  
  if (!config?.isShowChanges && !config?.cartInfo && !(Array.isArray(data?.new_value) || !Array.isArray(data?.old_value))) return null;

  let updatedBy = '';
  let newValue = '';
  let oldValue = '';
  let updatedAt = '';

  if (data && (data?.new_value || data?.old_value) && (Array.isArray(JSON.parse(data.new_value)) || Array.isArray(JSON.parse(data.old_value)))) {
    newValue = JSON.parse(data?.new_value).map((item: any)=> {
      const arr = [];
      for (const [key, value] of Object.entries(item)) {
        arr.push(`${key}: ${formatValueIfDate(String(value))}`);
      }
      return arr.join('\n');
    }).join('\n');
    
    oldValue = JSON.parse(data?.old_value).map((item: any)=> {
      const arr = [];
      for (const [key, value] of Object.entries(item)) {
        arr.push(`${key}: ${formatValueIfDate(String(value))}`);
      }
      return arr.join('\n');
    }).join('\n');
    
    updatedAt = data ? data?.updated_at : '-';
    updatedBy = data ? data?.updated_by : 'unknown';
  } else if ((data?.transfer1 || data?.transfer2) && config?.transferType) {
    newValue = data ? formatValueIfDate(data?.[config.transferType]?.[config.name]?.new_value || '-') : '-';
    oldValue = data ? formatValueIfDate(data?.[config.transferType]?.[config.name]?.old_value || '-') : '-';
    updatedAt = data ? data?.[config.transferType]?.[config.name]?.updated_at || '-' : '-';
    updatedBy = data ? data?.[config.transferType]?.[config.name]?.updated_by || '-' : 'unknown';
  } else {
    if (data && config?.vendorPhone) {
      newValue = data ? formatValueIfDate(data?.vendorPhone?.new_value) : '-';
      oldValue = data ? formatValueIfDate(data?.vendorPhone?.old_value) : '-';
      updatedAt = data ? data?.vendorPhone?.updated_at : '-';
      updatedBy = data ? data?.vendorPhone?.updated_by : 'unknown';
    } else if (data && config.name === 'attendeeName') {
      const newValueArr = safeParseNestedJson(data?.['attendeeNames']?.new_value);
      const oldValueArr = safeParseNestedJson(data?.['attendeeNames']?.old_value);

      newValue = Array.isArray(newValueArr)
        ? newValueArr.map((item: any) => formatValueIfDate(item.attendeeName)).join('\n')
        : '-';
      oldValue = Array.isArray(oldValueArr)
        ? oldValueArr.map((item: any) => formatValueIfDate(item.attendeeName)).join('\n')
        : '-';

      updatedAt = data?.['attendeeNames']?.updated_at || '-';
      updatedBy = data?.['attendeeNames']?.updated_by || 'unknown';
    } else if (data && config?.name === 'placeName' && config?.showDataFrom === 'venueAddress') {
      newValue = data ? formatValueIfDate(data?.['venueAddress']?.new_value) : '-';
      oldValue = data ? formatValueIfDate(data?.['venueAddress']?.old_value) : '-';
      updatedAt = data ? data?.['venueAddress']?.updated_at : '-';
      updatedBy = data ? data?.['venueAddress']?.updated_by : 'unknown';
    } else {
      newValue = data ? formatValueIfDate(data?.[config.name]?.new_value) : '-';
      oldValue = data ? formatValueIfDate(data?.[config.name]?.old_value) : '-';
      updatedAt = data ? data?.[config.name]?.updated_at : '-';
      updatedBy = data ? data?.[config.name]?.updated_by : 'unknown';
    }
  }

  return (
    <FieldChangesButton
      updated={newValue !== oldValue && oldValue !== 'unknown'}
      updatedBy={updatedBy}
      newValue={newValue}
      oldValue={oldValue}
      updatedAt={updatedAt}
      packageDetailsId={packageDetailsId}
      config={config}
      fieldName={config.name}
      transferType={config?.transferType ? config.transferType : ''}
    />
  );
};