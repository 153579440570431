import React from 'react';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { Button } from 'reactstrap';
import { successMsg } from '../../../cache/vars';
import {
  REMOVE_CARD,
  UPDATE_OR_CREATE_FULLY_EDITABLE_CART_DETAILS,
} from '../../../queries/vip-workflow';
import { NEW_CARD_ID } from '../../../utils/constants';
import FullyEditableFormSection from './FullyEditablePackageDetailsForm';
import { GET_PROFILE } from '../../../queries/auth';

const EditFullyEditableCardForm = ({
  modalState,
  setModalState,
  setPackageOrderWithDetails,
  setPackageDetailsId,
  setModalGroup,
  setModalName,
  setIsModalOpened,
  invoiceId,
  activeCardsFilter,
  cartJsonDataLogs,
  ticketTypes,
  packageOrderWithDetails,
}: any) => {
  const client = useApolloClient();
  
  const [getUserProfile, getUserProfileResponse] = useLazyQuery(
    GET_PROFILE,
    {
      fetchPolicy: 'network-only',
    },
  );
  
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
  
  const user = getUserProfileResponse?.data?.getProfile?.username ? getUserProfileResponse?.data?.getProfile?.username : getUserProfileResponse?.data?.getProfile?.email;
  
  React.useEffect(() => {
    getUserProfile();
    setIsButtonDisabled(false);
  }, [modalState]);

  return (
    <form
      className="new-event--form"
      onSubmit={async (e) => {
        e.preventDefault();
        setIsButtonDisabled(true);

        const { data } = await client.mutate({
          mutation: UPDATE_OR_CREATE_FULLY_EDITABLE_CART_DETAILS,
          variables: {
            editCartDetailsInput: {
              cartId: modalState.cartId,
              packageDetailsId: modalState.packageDetailsId,
              cartData: JSON.stringify(modalState.cartData),
              invoiceId: +invoiceId,
              orderDetailsFilter: activeCardsFilter || '',
            },
            user: user
          },
        });

        if (setPackageOrderWithDetails) {
          setPackageOrderWithDetails(data?.editFullyEditableCartDetails);
        }

        setPackageDetailsId(null);
        setModalGroup('');
        setModalName('');
        setModalState({});
        setIsModalOpened(false);
        successMsg('Saved');
      }}
    >
      <FullyEditableFormSection
        modalState={modalState}
        setModalState={setModalState}
        setPackageOrderWithDetails={setPackageOrderWithDetails}
        cartJsonDataLogs={cartJsonDataLogs}
        ticketTypes={ticketTypes}
        packageOrderWithDetails={packageOrderWithDetails}
      />
      <div className={`modal-footer pl-1 pt-0 d-flex ${modalState.typeId === NEW_CARD_ID ? 'justify-content-between' : 'justify-content-end'}`}>
        {modalState.typeId === NEW_CARD_ID ? (
          <button
            type="button"
            className="m-0 p-0"
            style={{ background: 'none', border: 'none', color: 'red', fontWeight: '600' }}
            onClick={async () => {
              if (window.confirm('Are you sure you want to delete this card?')) {
                const { data } = await client.mutate({
                  mutation: REMOVE_CARD,
                  variables: {
                    packageDetailsId: modalState.packageDetailsId,
                  },
                });

                if (data?.removePackageDetails === 'ok') {
                  setPackageOrderWithDetails((prev: any) => {
                    return {
                      ...prev,
                      orderDetails: prev.orderDetails.filter(
                        (item: any) => item.packageDetailsId !== modalState.packageDetailsId,
                      ),
                    };
                  });

                  setPackageDetailsId(null);
                  setModalGroup('');
                  setModalName('');
                  setIsModalOpened(false);
                  successMsg('Card deleted');
                }
              }
            }}
          >
            Delete card
          </button>
        ) : null}
        <Button className="new-event--add text-uppercase" color="primary" type="submit" disabled={isButtonDisabled || !user}>
          save
        </Button>
      </div>
    </form>
  );
};

export default EditFullyEditableCardForm;
