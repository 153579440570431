import React from 'react';
import { Form, FormGroup, Input, Spinner } from 'reactstrap';
import { errorMsg, successMsg, infoMsg } from '../../../../cache/vars';
import { UploadOutlined, PlusOutlined } from '@ant-design/icons';
import { FULLY_EDITABLE_CARDS, editableCartTypes } from '../../../../utils/constants';
import { ADD_FILE, ADD_URL } from '../../../../queries/vip-workflow';
import { useMutation } from '@apollo/client';
import { GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD, GET_TICKETS_LIST_FOR_TICKETS_MODAL } from '../../../../queries/ticket';
import { Button, Menu, Upload, UploadFile } from 'antd';
import './ticketTypeForm.scss';
import DropdownUrls from '../DropdownUrls/DropdownUrls';
import DropdownUpload from '../DropdownUpload/DropdownUpload';

const TicketTypeForm = ({ packageDetails, setPackageDetailsId, packageDetailsId, user }: any) => {
  const [files, setFiles] = React.useState<any[]>([]);
  const [showURLInput, setShowURLInput] = React.useState(false);
  const [urlInput, setUrlInput] = React.useState('');
  const [selectedCardTitle, setSelectedCardTitle] = React.useState('');
  const [isQrCode, setIsQrCode] = React.useState(false);

  const [uploadFile, uploadFileResponse] = useMutation(ADD_FILE, {
    refetchQueries: [GET_TICKETS_LIST_FOR_TICKETS_MODAL, 'getTicketsListForTicketsModal', GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD],
    fetchPolicy: 'no-cache',
    onCompleted() {
      successMsg('Saved');
      setFiles([]);
    },
  });
  
  const [uploadUrl, uploadUrlResponse] = useMutation(ADD_URL, {
    refetchQueries: [GET_TICKETS_LIST_FOR_TICKETS_MODAL, 'getTicketsListForTicketsModal', GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD],
    fetchPolicy: 'no-cache',
    onCompleted() {
      successMsg('Url Added');
      setUrlInput('');
    },
  });

  const uniqueCardTypes = packageDetails?.reduce((prev: any, cur: any) => {
    const cardTitle = cur.cartDetails?.cardTitle ? cur.cartDetails?.cardTitle : cur.group === 'VIP Club Access' ? cur.group : cur.type;
    
    const key = `${cur.type}-${cur.groupId}`;

    if (Object.keys(prev).includes(key)) {
      return prev;
    }

    prev[key] = { cardTitle, packageDetailsId: cur.packageDetailsId, group: cur.group };

    return prev;
  }, {});
  
  const isValidURL = (url: string) => {
    const pattern = new RegExp(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]+\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi,
    );
    return !!pattern.test(url);
  };
  
  const handleQRCodeButtonClick = () => {
    setIsQrCode(true);
    setShowURLInput(true);
  };

  const handleRegularURLButtonClick = () => {
    setIsQrCode(false);
    setShowURLInput(true);
  };
  
  const handleCancel = () => {
    setShowURLInput(false);
    setUrlInput('');
  };

  const handleSave = (e: React.MouseEvent<HTMLButtonElement>, title: string) => {
    e.preventDefault();
    if (isValidURL(urlInput) && packageDetailsId) {
      uploadUrl({
        variables: {
          title: title === 'Event Details' ? 'Event Tickets' : title,
          eTicketLink: urlInput,
          packageDetailsId: packageDetailsId,
          user: user,
          isQrCode: isQrCode
        },
      });
      setShowURLInput(false);
      setIsQrCode(false);
    } else {
      infoMsg('Invalid URL or Ticket Type is not selected');
    }
  };
  
  const handleTicketTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPackageDetailsId = Number.parseFloat(e.target.value);
    setPackageDetailsId(newPackageDetailsId);

    const selectedOptionKey = Object.keys(uniqueCardTypes).find(
      key => uniqueCardTypes[key].packageDetailsId === newPackageDetailsId
    );
    const selectedOption = selectedOptionKey ? uniqueCardTypes[selectedOptionKey] : null;
    
    if (selectedOption && selectedOption.cardTitle === 'Event Details') {
      setSelectedCardTitle('Event Tickets');
    } else {
      setSelectedCardTitle(selectedOption?.cardTitle);
    }
  };

  React.useEffect(() => {
    if (files.length) {
      const fileArray = files ? Array.from(files) : [];

      const checkedFilesSizeArray = fileArray.reduce((prev: UploadFile[], file: UploadFile) => {
        if (file.size! >= 8000000) {
          errorMsg(`File ${file.name} cannot be uploaded. Maximum size of file has acceded (8Mb).`);

          return prev;
        }

        prev.push(file);
        return prev;
      }, []);

      if (checkedFilesSizeArray.length) {
        const packageData = packageDetails.find(
          (item: any) => item.packageDetailsId === packageDetailsId,
        );

        uploadFile({
          variables: {
            title: selectedCardTitle === 'Event Details' ? 'Event Tickets' : selectedCardTitle,
            files: checkedFilesSizeArray,
            packageDetailsId: packageData.packageDetailsId,
            isQrCode: isQrCode,
            user: user
          },
        });
      } else {
        console.error('File error');
      }
    }
  }, [files]);

  return (
    <Form className="new-event--form ticket-type-form">
      <FormGroup className="">
        <label className="form-control-label">Choose Your Ticket Type</label>
        <Input
          className="text-capitalize"
          name="packageName"
          type="select"
          onChange={handleTicketTypeChange}
        >
          <option value=""></option>
          {uniqueCardTypes &&
            Object.entries(uniqueCardTypes).map(([key, value]: any, id) => {
              if (
                editableCartTypes.includes(value.group) ||
                FULLY_EDITABLE_CARDS.includes(value.group)
              ) {
                return (
                  <option value={value.packageDetailsId} key={id}>
                    {value.cardTitle === 'Event Details' ? 'Event Tickets' : value.cardTitle}
                  </option>
                );
              }
          })}
        </Input>
      </FormGroup>
      <FormGroup className="m-0">
        <DropdownUpload loading={uploadFileResponse.loading} disabled={!packageDetailsId}>
        <Menu.Item>
          <Upload
          accept=".pdf,.jpg,.jpeg,.png,.webp"
          multiple={true}
          showUploadList={false}
          fileList={files}
          defaultFileList={files}
          beforeUpload={(file) => {
            setFiles((prev) => [...prev, file]);
            return false;
          }}> 
            <div className="d-flex align-items-center">
              <UploadOutlined />
              <span className="ml-2">Upload ticket</span>
            </div>
          </Upload>
        </Menu.Item>
        
        <Menu.Item>
          <Upload
            accept=".pdf,.jpg,.jpeg,.png,.webp"
            multiple={true}
            showUploadList={false}
            fileList={files}
            defaultFileList={files}
            beforeUpload={(file) => {
              setIsQrCode(true);
              setFiles((prev) => [...prev, file]);
              return false;
            }}>
            <div className="d-flex align-items-center">
              <UploadOutlined />
              <span className="ml-2">Upload qr</span>
            </div>
          </Upload>
        </Menu.Item>
        </DropdownUpload>
      </FormGroup>
      {showURLInput ? (
        <div className='mt-3'>
          <div className="d-flex align-items-center" style={{ flex: 1, minWidth: '100px' }}>
            <Input
              value={urlInput}
              onChange={(e) => setUrlInput(e.target.value)}
              style={{ marginRight: '10px' }}
            />
            <button onClick={(e) => handleSave(e, selectedCardTitle)} className='btn btn-sm border-primary text-primary ml-3 mr-3'>Save</button>
            <button onClick={handleCancel} className='btn btn-sm border-primary text-primary'>Cancel</button>
          </div>
        </div>
        ) : (
          <>
            <p className='mt-2 mb-2'
              style={{ fontStyle: 'italic', color: '#525f7f', textAlign: 'center'}}>
              or
            </p>
            <div className='mt-2 mb-2 align-items-center' style={{ textAlign: 'center' }}>
              <DropdownUrls loading={uploadUrlResponse.loading} disabled={!packageDetailsId}>
                <Menu.Item>
                  <button
                    className="unstyled-button"
                    disabled={!packageDetailsId}
                    onClick={handleRegularURLButtonClick}
                  >
                    <div className="d-flex align-items-center">
                      <PlusOutlined />
                      <span className="ml-2">Add URL</span>
                    </div>
                  </button>
                </Menu.Item>
                
                <Menu.Item>
                  <button
                    className="unstyled-button"
                    disabled={!packageDetailsId}
                    onClick={handleQRCodeButtonClick}
                  >
                  <div className="d-flex align-items-center">
                    <PlusOutlined />
                    <span className="ml-2">Add QR Code LinkL</span>
                  </div>
                  </button>
                </Menu.Item>
              </DropdownUrls>
            </div>
          </>
        )}
    </Form>
  );
};

export default TicketTypeForm;
